
import './ComingSoon.css'

import tridentMark from '../assets/images/logo-magic-trident.png'

const ComingSoon = () => {

  return (
    <main id="coming-soon" className="coming-soon">
      <p>
        Follow the Jaya Ballard <span className="show-tablet-only show-desktop-only new-line"><br /></span>
        and Dominara United 
        <span className="nowrap"> story <a href="https://magic.wizards.com/en/story?utm_source=jaya-ballard&utm_medium=micro-site&utm_campaign=august-superdrop-2022" target="_blank" rel="noreferrer">here</a></span>
        <div class="coming-soon-trident">
          <img src={tridentMark} alt="" />
        </div>
      </p>
    </main>
  )

}

export default ComingSoon
