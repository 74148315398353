
import ReactPlayer from 'react-player/file'

import './DeskVideo.css';

const DeskVideo = ({ onEndedHandler, onErrorHandler }) => {

  // callbacks

  const onReady = () => {
    console.log('Video is ready')
  }

  const onEnded = () => {
    console.log('onEnded')
    console.log('bubble up event')
    onEndedHandler()
  }

  const onError = () => {
    console.log('onError')
    console.log('bubble up event')
    onErrorHandler()
  }

  // helper

  const getVideoUrl = (source) => {
    switch (source) {
      case 's3':
        return 'https://publicisdigital.s3.ca-central-1.amazonaws.com/sites/jayaballard/assets/video/intro-desktop.mp4'
      default:
        return '/assets/video/intro-desktop.mp4'
    }
  }
    
  return (
    <div className={`video-wrapper video-desktop`}>
      <ReactPlayer
        playing={true}
        muted={true}
        loop={false}
        onReady={onReady}
        onEnded={onEnded}
        onError={onError}
        id="DeskVideo"
        url={getVideoUrl('s3')}
      />
    </div>
  )

}

export default DeskVideo