import ButtonLink from '../components/ButtonLink'

import './Privacy.css'

const Privacy = ({ onClickClose }) => {

  const closePortal = () => { onClickClose() }

  return (
    <section id="portal__privacy" className="portal__privacy">



      <h2>Razorfish, a division of <span className="nowrap">Publicis Canada Inc.</span></h2>

      <div role="dialog" aria-labelledby="privacy_dialog_label" aria-modal="true">
        <h1 id="privacy_dialog_label">Privacy Notice</h1>
      </div>

        <p>
          JayaBallard.com is a microsite for that provides information on the fictional character of Jaya Ballard and is managed by Razorfish, a division of Publicis Canada Inc. (“Razorfish”). This Privacy Notice sets out how we use and protect any information we collect through this website.
        </p>



        <h3>Introduction</h3>

        <p>
          Any data collected through the <a href="https://jayballard.com/">https://jayballard.com/</a> website (the “Website”) including personal information, will only be used for the purposes set out at Section 02. in this Privacy Notice. Once collected, personal information will not be used for a different incompatible purpose without your consent. Razorfish, 111 Queen St E Suite 200, Toronto, ON M5C 1S2, is regarded as the Data Controller.<br /><br />

          We take the protection of data privacy very seriously. All personal information collected through the Website will be treated as strictly confidential and will only be used as set out in this Privacy Notice. The applicable data protection laws shall be respected in full.<br /><br />

          Therefore, please read this Privacy Notice thoroughly. <strong>If you disagree with the way personal information will be used, please do not use this Website or provide your personal information through the Website.</strong><br /><br />

          We may include links on the Website to other websites including social media platforms. This Privacy Notice only covers how we use personal information and does not cover these other websites. We encourage you to read the privacy notices available on other websites you visit.
        </p>



        <h3>01. What personal information do we collect and process?</h3>

        <p>
          The following table describes the categories of personal information we collect and the sources of such personal information.
        </p>

        <h4 className="mt-40">Category</h4>

        <h5>Identification and contact data</h5>

        <p>
          E.g. online identifiers, IP address, and mobile identifiers.
        </p>

        <h5>Internet or other electronic network activity information (technical data)</h5>

        <p>
          E.g., browsing history, search history, and information on a consumer’s interaction with a website, application, or advertisement.<br /><br />

          Your verbatim communications to us or shared on the public areas of the website.
        </p>

        <h4>Categories of Sources</h4>

        <ul>
          <li>We may collect this from internet service providers, data analytics providers, operating systems and social networks.</li>
          <li>We may collect this from internet service providers, data analytics providers, operating systems and social networks.</li>
          <li>We will collect this directly from you when you send us your questions or comments through email or other forms on our Website that may be available from time to time.</li>
        </ul>



        <h3>02. Purpose of data collection and legal grounds we rely on</h3>

        <h4>
          Our purposes for collecting and using personal information
        </h4>

        <ul>
          <li>To read and respond to your queries sent using email or an online form on our Website.</li>
          <li>Operating our website and services, for example:</li>
          <ul>
            <li>improving, testing, updating and verifying our own database.</li>
            <li>developing new products.</li>
            <li>operating, analyzing, improving, and securing our website and services.</li>
          </ul>
          <li>Other internal purposes, for example:</li>
          <ul>
            <li>For internal research, internal operations, auditing, detecting security incidents, debugging, short-term and transient use, quality control, statistics about user interaction on our Website, and legal compliance. We use the information collected from our own Website for the above, as well as for our own marketing purposes.</li>
          </ul>
        </ul>

        <h4>
          Basis for processing
        </h4>

        <ul>
          <li>Our legitimate interest to improve our Website, services and develop new products.</li>
          <li>Our legitimate interest to support our internal operations and conduct research.</li>
        </ul>



        <h3>03. With whom do we share personal information?</h3>

        <p>
          We will share your information for various business purposes, with employees of Razorfish, other Publicis Groupe entities, and with our service providers.<br /><br />

          <b>Aggregate, de-identified, or anonymised information:</b> We may aggregate, de-identify and/or anonymise any information collected so that such information can no longer be linked to you or your device (“<b>Aggregate/De-Identified Information</b>”). We may use Aggregate/De-Identified Information for any purpose, including without limitation for research and marketing purposes, and may also share such data with any third parties, including clients, advertisers, promotional partners, and sponsors, in our discretion.<br /><br />

          <b>Sharing with other group entities or service providers:</b> As a global organization, we may share personal information with other entities within our group or with selected service providers (as described under IV), either to benefit from additional support (technical or otherwise), or to provide you with services you have requested from us.<br /><br />

          <b>Sharing for legal purposes:</b> In addition, we may share personal information with third parties in order to: (a) comply with legal process or a regulatory investigation (e.g. regulatory authorities’ investigation, subpoena, or court order); (b) enforce this Privacy Notice or other contracts with you, including investigation of potential violations thereof; (c) respond to claims that any content violates the rights of third parties; and/or (d) protect the rights, property or personal safety of us, our platform, our customers, our agents and affiliates, its users and/or the public. We likewise may provide information to other companies and organizations (including law enforcement) for fraud protection, and spam/malware prevention, and similar purposes.<br /><br />

          <b>Sharing in event of a corporate transaction:</b> We may also share personal information in the event of a major corporate transaction, including for example a merger, investment, acquisition, reorganization, consolidation, bankruptcy, liquidation, or sale of some or all of our assets, or for purposes of due diligence connected with any such transaction.
        </p>



        <h3>04. Who is processing the personal information?</h3>

        <p>
          We have engaged Amazon Web Services to help with hosting the Website.<br /><br />

          We have also engaged AddThis, a social media plug-in to help us in managing our social media sharing marketing efforts.<br /><br />

          We have also engaged Google Analytics to help provide us with information about how you interact with the Website (e.g., clicks, internal links, pages visited, scrolling, searches, time stamps).<br /><br />

          If the services of third parties for the technical maintenance, administration or processing of data are required, your personal information will be accessed by these parties only to the extent that is required to ensure a smooth and secure technical handling of the Website and in compliance with applicable law, including with respect to data confidentiality, privacy and security.
        </p>



        <h3>05. Your rights regarding your personal information</h3>

        <p>
          You may exercise the following legal rights regarding any personal information that we hold about you.
        </p>

        <ul>
          <li>The right of access</li>
          <li>The right to rectification</li>
          <li>The right to erasure</li>
          <li>The right to object</li>
          <li>The right to restrict processing</li>
          <li>The right to data portability</li>
          <li>The right to withdraw your consent to the processing of your personal information at any time</li>
        </ul>
        <br />

        <p>
          Your personal information will be stored in accordance with our applicable data retention requirements and corporate policies. The retention period for your personal information varies depending on the type of personal information and the purposes of processing it.
        </p>



        <h3>06. Viewing, editing, updating or deletion of your personal information:</h3>

        <p>
          Please contact <a href="mailto:privacyofficer@publicisgroupe.com">privacyofficer@publicisgroupe.com</a> if you want to see any personal information stored about you in our records. We will honor your legal right to view your personal information in accordance with applicable privacy law. If you think that any of the personal information collected through the Website is incorrect, confusing or incomplete, please contact <a href="mailto:privacyofficer@publicisgroupe.com">privacyofficer@publicisgroupe.com</a>. You may also ask us to erase, restrict or port your personal information and you may tell us if you object to our use of your personal information.<br /><br />

          Personal information will be stored by us in accordance with our applicable data retention requirements and corporate policies.
        </p>



        <h3>07. Data security</h3>

        <p>
          We use a variety of methods, such as firewalls, intrusion detection software and manual security procedures, to secure your data against loss or damage and to help protect the accuracy and security of personal information and to prevent unauthorized access or improper use. If you think that the Website or any personal information is not secure or that there has been unauthorized access to the Website or your personal information, please contact <a href="mailto:privacyofficer@publicisgroupe.com">privacyofficer@publicisgroupe.com</a> immediately.
        </p>



        <h3>08. Confidentiality</h3>

        <p>
          Please note that we treat personal information as strictly confidential.
        </p>



        <h3>09. Data transfers</h3>

        <p>
          Your personal information is transferred to the US which is not considered to have the same level of data protection as in the EU. However we ensure all data transfers comply with applicable legal requirements (for example, under intercompany agreements with appropriate contractual protections or standard contractual clauses).
        </p>



        <h3>10. Use of cookies, other tracking technology, social media and social media plug-ins</h3>

        <p>
          Cookies are small pieces of text or code sent to your device when you visit the Website. Cookies are used to optimize the viewing experience, count visitors to a webpage, troubleshoot any problems, keep websites secure, and better serve content. The following cookies are used on the Website:
        </p>

        <ul>
          <li>Functional and required cookies are always used to allow our hosting platform, to securely serve this Website to you.</li>
          <li>Analytics and performance cookies are used on the Website to view site traffic, activity, and other analytics data.</li>
        </ul>
        <br />

        <p>
          Overall, cookies help us provide you with a better website, by enabling us to monitor which pages you find useful and which you do not. You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website.
          To OPT-OUT of Google Analytics, you can learn more here.<br /><br />

          If you follow us on social media (for example, through our accounts on LinkedIn, Facebook, Twitter or Instagram), we may also collect personal information about you in order to understand our followers better and understand the public response to our products and services.<br /><br />

          We utilize social media plug-ins to allow you to easily share content from our website and, in doing so, your personal information will be disclosed to these social media platforms. When you share content using these buttons, a new page will pop up from the relevant social media platform. If you’re already logged into your account on that social media platform, then you will probably be automatically logged into this pop-up page, so that you don’t have to log in again. If, however, you are not logged in (or if you are not registered with the social media platform), the page will ask you for your information.<br /><br />

          We have no control over how social media platforms use your personal information. We encourage you to read the privacy notices on the various social media platforms you use.<br /><br />

          Find out more about how these social media platforms use your personal information:
        </p>

        <ul>
          <li><a href="https://www.addthis.com/privacy/index" target="_blank" rel="noreferrer">AddThis</a></li>
          <li><a href="https://en-gb.facebook.com/privacy/explanation" target="_blank" rel="noreferrer">Facebook</a></li>
          <li><a href="https://policies.google.com/privacy?hl=en" target="_blank" rel="noreferrer">Google</a></li>
          <li><a href="https://twitter.com/en/privacy" target="_blank" rel="noreferrer">Twitter</a></li>
        </ul>
        <br />

        <p>
          We may incorporate Tracking Technologies into our own Service (including our website and emails) as well as into ads displayed on other websites and services. Some of these Tracking Technologies may track your activities across time and services for purposes of associating the different devices you use, and delivering relevant ads and/or other content to you on the Service and other services after you have left the Service (“Interest-based Advertising”).<br /><br />

          We also use audience matching services to reach people (or people similar to people) who have visited our Service or are identified in one or more of our databases (“Matched Ads”). This is done by us uploading a customer list to a technology service or incorporating a pixel from a technology service into our own Service, and the technology service matching common factors between our data and their data. For instance, we incorporate the Facebook pixel on our Service as part of our use of Facebook Custom Audiences.
        </p>



        <h3>12. Notification of changes</h3>

        <p>
          Should we wish to change this Privacy Notice, if you have already provided personal information through this Website, we will contact you to inform you of the new Privacy Notice and, in some circumstances, we may need to obtain your consent to the changes. In other circumstances, any such changes to this Privacy Notice will be promptly communicated on this page and you should check back to see whether there are any changes. Continued use of the Website after a change in the Privacy Notice indicates your acknowledgement and acceptance of the use of personal information in accordance with the amended Privacy Notice.<br /><br />

          If you wish to save this text, please mark the entire statement (e.g. with your mouse) and copy-paste by pushing ctrl-c.
        </p>



        <h3>12. Further information</h3>

        <p>
          If you consider that we are not complying with this Privacy Notice or if you have any questions in relation to this Privacy Notice, please contact <a href="mailto:privacyofficer@publicisgroupe.com">privacyofficer@publicisgroupe.com</a> You may also lodge a formal complaint with your competent data protection authority.<br /><br />

          If you have any questions about Razorfish’s collection of your data, you wish to exercise your rights or know about the contractual protections in place, please contact the Publicis Chief Data Privacy Officer on <a href="mailto:privacyofficer@publicisgroupe.com"><b>privacyofficer@publicisgroupe.com</b></a>.<br /><br />

          If you think that we are not compliant with the principles set out in this Privacy Notice, if you are unsatisfied with the way we process your personal information or if your request to exercise your rights set forth above has been rejected, you may also lodge a formal complaint with your local competent data protection authority.<br /><br />

          Updated: 18/08/2022
        </p>



        <h3>12. Additional rights for California residents</h3>

        <p>
          The California Consumer Privacy Act of 2018 (“CCPA”) provides certain rights to residents of California. This section of the Privacy Notice applies if you are a natural person who is a resident of California (“California Consumer”) and you have provided your personal information to us. If you are not a California Consumer (or a resident of California), this does not apply to you and you should not rely on it. Without being discriminated against for exercising these rights, California Consumers have the right to request that we disclose what personal information we collect from you, to delete that information, and to opt-out of the sale of your personal information, subject to certain restrictions. You also have the right to designate an agent to exercise these rights on your behalf. This section describes how to exercise those rights and our process for handling those requests. (To the extent permitted by applicable law, we may charge a reasonable fee to comply with your request.)<br /><br />

          <b>a. Right to request access to your personal information</b><br />
          California Consumers have the right to request that we disclose what categories of your personal information that we collect, use, or sell. You may also request the specific pieces of personal information that we have collected from you. However, we may withhold some personal information where the risk to you or our business is too great to disclose the information.<br /><br />

          <b>b. Right to request deletion of your personal information</b><br />
          You may also request that we delete any personal information that we collected from you, such as if you have been a customer of ours. (Note that this is different from your right to “opt out” of us selling your personal information, which is described below; also note that we do not generally collect personal information directly from consumers.) However, we may retain personal information for certain important purposes, such as (a) to protect our business, systems, and users from fraudulent activity, (b) to address technical issues that impair existing functionality (such as de-bugging purposes), (c) as necessary for us, or others, to exercise their free speech or other rights, (d) to comply with law enforcement requests pursuant to lawful process, (e) for scientific or historical research, (f) for our own internal purposes reasonably related to your relationship with us, or to comply with legal obligations. Additionally, we need certain types of information so that we can provide our services to you. If you ask us to delete it, you may no longer be able to access or use our services.<br /><br />

          <b>c. How to exercise your access, opt-out and (if applicable) deletion rights</b><br />
          California Consumers may exercise their California privacy rights by sending an email to <a href="mailto:privacyofficer@publicisgroupe.com">privacyofficer@publicisgroupe.com</a>.<br /><br />

          For security purposes (and as required under California law), we will verify your identity – in part by requesting certain information from you — when you request to exercise your California privacy rights. For instance, if you request categories or specific pieces of personal information we have received about you, you may need to confirm your possession of an identifier (such as an email address) or to provide a piece of identification that confirms you are the person you claim to be.<br /><br />

          Once we have verified your identity, we will respond to your request as appropriate:
        </p>

        <ul>
          <li>Where you have requested the categories of personal information that we have collected about you, we will provide a list of those categories.</li>

          <li>Where you have requested specific pieces of personal information, we will provide the information you have requested, to the extent required under the CCPA and provided we do not believe there is an overriding privacy or security concern to doing so.</li>

          <li>Where you have requested that we delete personal information that we have collected from you, we will seek to confirm whether your request is for an “opt out” or a “deletion”: because “opt out” or “do not sell” rights enable us to maintain your information for “suppression” purposes – i.e., to prevent us from selling information about you in the future (which is what many consumers requesting “deletion” actually desire to occur), we try to explain this in order to ensure we are meeting consumers’ preferences.</li>

          <li>Upon completion of the above process, we will send you a notice that explains the categories of personal information we were able to locate about you, whether we (1) deleted, (2) de-identified, or (3) retained the information we collected from you. Certain information may be exempt from such requests under applicable law.</li>
        </ul>
        <br />

        <p>
          If we are unable to complete your requests fully for any of the reasons above, we will provide you additional information about the reasons that we could not comply with your request.<br /><br />

          <b>d. Right to nondiscrimination</b><br />
          We will not deny, charge different prices for, or provide a different level of quality of goods or services if you choose to exercise these rights.<br /><br />

          <b>e. Right to opt-out of the sale of your personal information</b><br />
          We do not sell any personal information as the term “sell” is used in the CCPA.<br /><br />

          <b>f. Information about persons under 16 years of age</b><br />
          We do not knowingly collect personal information from minors under 16 years of age in California unless we have received legal consent to do so. If we learn that personal information from such California Consumers has been collected, we will take reasonable steps to remove their information from our database (or to obtain legally required consent).<br /><br />

          <b>g. Authorized agents</b><br />
          You may also designate an agent to make requests to exercise your rights under CCPA as described above. We will take steps both to verify the identity of the person seeking to exercise their rights as listed above, and to verify that your agent has been authorized to make a request on your behalf through providing us with a signed written authorization or a copy of a power of attorney.<br /><br />
        </p>

        <br /><br />
        <ButtonLink id="legal_dialog_close" classes="close btnLink" tabIndex="0" text="Return to website" onClick={closePortal} />

    </section>
  
  )
}

export default Privacy
