import { useEffect } from 'react'

import Privacy from './Privacy'
import Terms from './Terms'

import './portals.css'

const Portals = () => {

  useEffect(() => {

    // close modal window with escape key
    document.addEventListener('keydown', function (e) {
      if (e.key === 'Escape') onClickClose()
    })

  })

  const onClickClose = () => {
    const portals = document.getElementById('portals')
    if (portals !== null) {
      portals.classList.add('fade-out')
      setTimeout(() => {
        portals.scrollTop = 0
        portals.style.display = 'none'
        portals.classList.remove('fade-in')
        portals.classList.remove('fade-out')
        const portalSections = document.querySelectorAll('#portals section')
        if ( portalSections !== null ) {
          portalSections.forEach((section) => {
            section.style.display = 'none'
          })
        }
      }, 500)
    }
  }

  const openTerms = (e) => {
    e.target.blur()
    const portals = document.getElementById('portals')
    const termsPortal = document.getElementById('portal__terms')
    if (portals !== null && termsPortal !== null) {
      termsPortal.style.display = 'block'
      portals.style.display = 'block'
      portals.classList.add('fade-in')
    }
  }

  const openPrivacy = (e) => {
    e.target.blur()
    const portals = document.getElementById('portals')
    const privacyPortal = document.getElementById('portal__privacy')
    if (portals !== null && privacyPortal !== null) {
      privacyPortal.style.display = 'block'
      portals.style.display = 'block'
      portals.classList.add('fade-in')
    }
  }

  return (
    <>
      <div id="portals" className="portrait-only">
        <div className="portal__inner">
          <div className="portal__header">
            <a onClick={onClickClose} className="close">Close</a>
            &nbsp;
          </div>
          <div className="portal__content">
            <Privacy onClickClose={onClickClose} />
            <Terms onClickClose={onClickClose} />
          </div>
        </div>
      </div>
      {
        1 === 0 &&
          <div id="portal-links">
            <nav>
              <ul>
                <li>Powered by Razorfish.</li>
                <li><a onClick={openPrivacy}>Privacy</a></li>
                <li><a onClick={openTerms}>Terms</a></li>
              </ul>
            </nav>
          </div>
      }
    </>
  )

}

export default Portals