import { useEffect, useRef } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import { isMobile, isTablet } from 'react-device-detect'
import useScreenOrientation from 'use-screen-orientation'

import browserUtils from './utils/browserUtils'
import config from './config/index'
import securityUtils from './utils/securityUtils'

import BlockLandscape from './pages/BlockLandscape'
import ComingSoon from './pages/ComingSoon'
import Main from './pages/Main'
import Offline from './pages/Offline'
import Portals from './portals/Portals'

// config
const envConfig = config.getEnvConfig()

// setup dependencies
const querystring = new URLSearchParams(window.location.search)
const querystringBypass = querystring.get('bypass')
const querystringBypassHash = querystringBypass !== null ? securityUtils.getHash(querystring.get('bypass')).toString() : ''

function getShowOffline() {
  if (envConfig.frontend.offline && querystringBypassHash !== envConfig.frontend.bypassHash) return true
  return false
}

function getShowComingSoon() {
  if (envConfig.frontend.comingSoon && querystringBypassHash !== envConfig.frontend.bypassHash) return true
  return false
}

function App() {

  /*
  -----------------------------------------------------------------
  setup
  -----------------------------------------------------------------
  */

  // useRef
  const showOffline = useRef(getShowOffline())
  const showComingSoon = useRef(getShowComingSoon())
  const blockNavLinks = useRef(false)

  // screen orientation
  const [orientation, angle] = useScreenOrientation()

  // runtime setup
  if (envConfig.frontend.disableConsole) browserUtils.disableConsole()

  /*
  -----------------------------------------------------------------
  useEffect
  -----------------------------------------------------------------
  */

  useEffect(() => {

    // add background image to body
    const body = document.querySelector('body')
    if (body !== null) {
      // add background image
      if (showOffline.current || showComingSoon.current) {
        body.classList.add('body-bg-leather')
      } else {
        body.classList.add('body-bg-wood')
      }
      // hide nav links
      if (blockNavLinks.current) {
        body.classList.add('block-nav-links')
        body.classList.add('block-AddThis')
      }
    }

    // console.log('mobile', window.innerWidth, orientation, angle)
    // alert('mobile', window.innerWidth, orientation, angle)

  }, [blockNavLinks]);

  /*
  -----------------------------------------------------------------
  views
  -----------------------------------------------------------------
  */

  // show: offline (eg, maintenance mode)
  if (showOffline.current) {
    blockNavLinks.current = true
    return (
      <>
        <Router>
          <Routes>
            <Route path='*' element={<Offline />} />
          </Routes>
        </Router>
        <Portals />
      </>
    )
  }

  // show: coming soon
  if (showComingSoon.current) {
    blockNavLinks.current = true
    return (
      <>
        <Router>
          <Routes>
            <Route path='*' element={<ComingSoon />} />
          </Routes>
        </Router>
      </>
    )
  }

  // mobile: portrait only
  if (isMobile || isTablet) {
    if (orientation !== 'portrait' || angle !== 0) {
      blockNavLinks.current = true
      return (
        <>
          <Router>
            <Routes>
              <Route path='*' element={<BlockLandscape />} />
            </Routes>
          </Router>
        </>
      )
    }
  }

  // return live UX
  return (
    <div className="site-wrapper">
      <div className="site-container">
        <Router>
          <Routes>
            <Route path='*' element={<Main />} />
          </Routes>
        </Router>
      </div >
      <Portals />
    </div>
  );

}

export default App;
