
const securityUtils = {

  /*
  ---------------------------------------------------------------
  HASHSING
  ---------------------------------------------------------------
  */

  // https://stackoverflow.com/posts/65239086/revisions
  getHash(str) {
    let hash = 0
    for (let i = 0; i < str.length; ++i)
      hash = Math.imul(31, hash) + str.charCodeAt(i)
    return hash | 0
  }

}

export default securityUtils
