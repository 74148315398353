import PropTypes from 'prop-types'
import './ButtonLink.css'

const ButtonLink = ({classes, onClick, text}) => {

  classes = classes.replace(',', ' ') + ' ipm-regular'

  return <button 
    className={classes}
    onClick={onClick}
    >
      {text}
    </button>;
};

// default button click (if no function is provided in props)
const onClickVoid = () => {}

ButtonLink.defaultProps = {
  classes: "btnLink",
  text: "",
  onClick: onClickVoid,
};

ButtonLink.propTypes = {
  classes: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
};

export default ButtonLink
