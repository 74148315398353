
import './BlockLandscape.css'

const BlockLandscape = () => {

  return (
    <main id="block-landscape" className="block-landscape">
      <p>
        Please rotate your <span class="nowrap">mobile device.</span>
      </p>
    </main>
  )

}

export default BlockLandscape
