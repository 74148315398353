import ButtonLink from '../components/ButtonLink'

import './Terms.css'

const Terms = ({ onClickClose }) => {

  const closePortal = () => { onClickClose() }

  return (
    <section id="portal__terms" className="portal__terms">



      <h2>Razorfish, a division of <span className="nowrap">Publicis Canada Inc.</span></h2>

      <div role="dialog" aria-labelledby="terms_dialog_label" aria-modal="true">
        <h1 id="terms_dialog_label">Website Terms & Conditions</h1>
        <p>
          Effective Date August 17, 2022
        </p>
      </div>

      <p>
        Razorfish, a division of Publicis Canada Inc. (“Razorfish”) provides the content on this Web site (the "Site") subject to the following terms and conditions (the "Terms"). By accessing and using this Site, you (“you”) agree to these Terms. We may periodically change the Terms, so please check back from time to time. You will be subject to, and will be deemed to have been made aware of and to have accepted, the changes in any revised Terms by your continued use of the Site after the date such revised Terms become effective. For an explanation of Razorfish’s practices and policies related to the collection, use, and storage of our users’ information, please read our Privacy Policy.
      </p>



      <h3>Introduction</h3>

      <p>
        All content and functionality on the Site, including text, graphics, logos, icons, and images and the selection and arrangement thereof (“Site Content”), is the exclusive property of Razorfish, its affiliates or its licensors and is protected by Canadian, U.S. and international copyright laws. The trademarks, service marks, designs, and logos (collectively, the "Trademarks") displayed on the Site are the registered and unregistered Trademarks of Razorfish, its affiliates and its licensors. All rights not expressly granted are reserved.<br /><br />

        Any reproduction or redistribution of the Site Content and functionality displayed on the Site in whole or in part, is expressly prohibited by law, and may result in criminal or civil fines. You may not reproduce, modify, distribute, transmit, post, or disclose the Site Content without Razorfish’s prior written consent.<br /><br />

        You agree that you will not refer to or attribute any Site Content to Razorfish or its affiliates or licensors in any public medium (e.g., press release, Web sites) for advertising or promotion purposes or for the purpose of informing or influencing any third party, and that you will not use or reproduce any Trademark of, or imply any endorsement by or relationship with, Razorfish or its affiliates or licensors.<br /><br />

        You agree that you will not (i) use the Site in any way that (a) is, or may be, damaging to the Site; (b) impacts user access to the Site or interferes with, disrupts, or creates an undue burden on the Site or the networks or services connected to the Site; or (c) is contrary to applicable laws and regulations, or in a way that causes, or may cause, harm to the Site, or to any person or business entity; (ii) access the Site through automated or non-human means, whether through a bot, script, or otherwise; circumvent, disable, or otherwise interfere with security-related features of the Site, including features that prevent or restrict the use or copying of any Site Content or enforce limitations on the use of the Site and/or the Site Content contained therein; (iii) use the Site as part of any effort to compete with us or otherwise use the Site and/or the Site Content for any revenue-generating endeavor or commercial enterprise; (iv) decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way making up a part of the Site (v) upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material that interferes with any party’s uninterrupted use and enjoyment of the Site or modifies, impairs, disrupts, alters, or interferes with the use, features, functions, operation, or maintenance of the Site or (vi) disparage, tarnish, or otherwise harm, in our opinion, us and/or the Site.
      </p>



      <h3>02. Notices of Infringement</h3>

      <p>
        Razorfish prohibits the posting of any content that infringes or violates the copyright rights and/or other intellectual property rights (including rights of privacy and publicity) of any person or entity. If you believe that your intellectual property right (or such a right that you are responsible for enforcing) is infringed by any content on the Site, please write to Razorfish at the address shown below, giving a written statement that contains: (a) identification of the copyrighted work and/or intellectual property right claimed to have been infringed; (b) identification of the allegedly infringing material on the Site that is requested to be removed; (c) your name, address, and daytime telephone number, and an e-mail address if available; (d) a statement that you have a good faith belief that the use of the copyrighted work and/or exercise of the intellectual property right is not authorized by the owner, its agent, or the law; (e) a statement that the information in the notification is accurate, and, under penalty of perjury, that the signatory is authorized to act on behalf of the owner of the right that is allegedly infringed; and (f) the signature of the intellectual property right owner or someone authorized on the owner’s behalf to assert infringement of the right. Razorfish will remove any posted content that infringes the copyright or other intellectual property right of any person under law upon receipt of such a statement. Razorfish’s contact for submission of notices under this Section 2 is: Razorfish, a division of Publicis Canada Inc., 111 Queen St E Suite 200, Toronto, <span className="nowrap">ON M5C 1S2.</span>
      </p>



      <h3>03. Disclaimers</h3>

      <p>
        THE CONTENT AND FUNCTIONALITY ON THE SITE IS PROVIDED WITH THE UNDERSTANDING THAT RAZORFISH IS NOT HEREIN ENGAGED IN RENDERING PROFESSIONAL ADVICE AND SERVICES TO YOU. ALL CONTENT AND FUNCTIONALITY ON THE SITE IS PROVIDED "AS IS," WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. RAZORFISH AND ITS THIRD-PARTY CONTENT PROVIDERS MAKE NO WARRANTIES, EXPRESS OR IMPLIED, AS TO THE OWNERSHIP, ACCURACY, OR ADEQUACY OF THE SITE CONTENT. RAZORFISH SHALL HAVE NO LIABILITY OR RESPONSIBILITY FOR ANY INFORMATION PUBLISHED ON LINKED WEB SITES, CONTAINED IN ANY CONTENT PUBLISHED ON THE SITE, OR PROVIDED BY THIRD PARTIES. NEITHER RAZORFISH NOR ITS THIRD-PARTY CONTENT PROVIDERS SHALL BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES OR FOR LOST REVENUES OR PROFITS, WHETHER OR NOT ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR LOSSES AND REGARDLESS OF THE THEORY OF LIABILITY.
      </p>



      <h3>04. Third-Party Web Sites</h3>

      <p>
        We may provide links to third-party websites (“Third Party Websites”), and some of the content appearing to be on this Site is in fact supplied by third parties (“Third Party Content”), for example, in instances of framing of Third Party Websites or incorporation through framesets of content supplied by third-party servers. Such Third-Party Websites and Third-Party Content are not investigated, monitored, or checked for accuracy, appropriateness, or completeness by us, and we are not responsible for any Third-Party Websites accessed through the Site or any Third-Party Content posted on, available through, or installed from the Site, including the content, accuracy, offensiveness, opinions, reliability, privacy practices, or other policies of or contained in the Third-Party Websites or the Third-Party Content. Inclusion of, linking to, or permitting the use or installation of any Third-Party Websites or any Third-Party Content does not imply approval or endorsement thereof by us. If you decide to leave the Site and access the Third-Party Websites or to use or install any Third-Party Content, you do so at your own risk, and you should be aware these Terms and Conditions no longer govern.
      </p>



      <h3>05. Indemnification</h3>

      <p>
        You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our respective officers, agents, partners, and employees, from and against any loss, damage, liability, claim, or demand, including reasonable attorneys’ fees and expenses, made by any third party due to or arising out of: (1) your use of the Site; (2) your breach of these Terms and Conditions or (6) your infringement of any intellectual property or other right of anyone.<br /><br />

        Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate, at your expense, with our defense of such claims. We will use reasonable efforts to notify you of any such claim, action, or proceeding which is subject to this indemnification upon becoming aware of it.
      </p>



      <h3>06. Governing Law; Jurisdiction</h3>

      <p>
        You agree that these Terms and any claim, dispute or controversy (whether in contract, tort or otherwise, whether pre-existing, present or future, and including statutory, common law and equitable claims) between you and Razorfish arising from or relating to these Terms, its application, interpretation or breach, termination or validity, the Site, or any related transaction, shall, to the maximum extent permitted by applicable law, be governed by the laws of the province of Ontario, Canada, without regard to its conflicts of laws rules. You agree that all claims you may have arising from or relating to the operation, use or other exploitation of the Site will be heard and resolved in the province of Ontario, Canada. Any claim or cause of action arising out of or related to your use of the Site must be filed within one (1) year after such claim or cause of action arose, regardless of any statute or law to the contrary. In the event any such claim or cause of action is not filed within such one (1) year period, such claim or cause of action shall be forever barred.<br /><br />
      </p>

      
      <ButtonLink id="legal_dialog_close" classes="close btnLink" tabIndex="0" text="Return to website" onClick={closePortal} />

    </section>
  )

}

export default Terms
