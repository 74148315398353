import browserUtils from '../utils/browserUtils'

const config = {

  getEnvConfig() {
    const domain = browserUtils.getDomain()
    if (typeof domain !== 'string') {
      console.log(`Invalid domain: ${domain}`)
      return false
    }
    switch (domain) {
      case 'jayaballard.com':
      case 'www.jayaballard.com':
        return this.env.production
      case 'jayaballard.pubtor.com':
        return this.env.stage
      default:
        return this.env.development
    }
  },

  env: {

    production: {
      frontend: {
        disableConsole: false,
        comingSoon: false,
        offline: false,
        bypassHash: '-1433646595',
      }
    },

    stage: {
      frontend: {
        disableConsole: false,
        comingSoon: false,
        offline: false,
        bypassHash: '-1433646595',
      }
    },

    development: {
      frontend: {
        disableConsole: false,
        comingSoon: false,
        offline: false,
        bypassHash: '-1433646595',
      }
    }

  },

  flipbook: {
    mobile: {
      breakpoint: 480,
      aspectRatio: 1.77,
      heightAdj: 100,
      maxWidth: 460,
      maxHeight: 760
    },
    tablet: {
      breakpoint: 1159,
      aspectRatio: 1.377,
      heightAdj: 80,
      maxWidth: 640,
      maxHeight: 1050
    },
    desktop: {
      breakpoint: null,
      aspectRatio: .6435,
      heightAdj: 80,
      minWidth: 400,
      maxWidth: 900,
      minHeight: 600,
      maxHeight: 800
    },
    react: {
      redrawPixels: 15,
    }
  },

  roseDrop: {
    frames_p1: [
      '#frame_01', '#frame_02', '#frame_03', '#frame_04', '#frame_05', '#frame_06', '#frame_07', '#frame_08', '#frame_09', '#frame_10', '#frame_11', '#frame_12', '#frame_13', '#frame_14', '#frame_15', '#frame_16', '#frame_17', '#frame_18', '#frame_19', '#frame_20', '#frame_21'
    ],
    frames_kill_p1: [
      '#frame_01', '#frame_02', '#frame_03', '#frame_04', '#frame_05', '#frame_06', '#frame_07', '#frame_08', '#frame_09', '#frame_10', '#frame_11', '#frame_12', '#frame_13', '#frame_14', '#frame_15', '#frame_16', '#frame_17', '#frame_18', '#frame_19', '#frame_20'
    ],
    frames_p2: [
      '#frame_21', '#frame_22', '#frame_23', '#frame_24', '#frame_25', '#frame_26', '#frame_27', '#frame_28', '#frame_29', '#frame_30', '#frame_31', '#frame_32'
    ]
  }

}

export default config
