import { useEffect, useRef, useState } from 'react'

import config from './../../config/index'
import flipbookUtils from '../../utils/flipbookUtils'
import browserUtils from '../../utils/browserUtils'

import BookDesktop from './BookDesktop'
import BookMobile from './BookMobile'
import BookTablet from './BookTablet'

import './Book.css'

const bookConfig = config.flipbook

const Book = () => {

  /*
  -----------------------------------------------------------------
  helpers
  -----------------------------------------------------------------
  */

  // increment component keys to trigger redraw (via remounting of component)
  const incrementKeys = () => {
    setDesktopKey(desktopKey + 1)
    setMobileKey(mobileKey + 1)
    setTabletKey(tabletKey + 1)
  }

  /*
  -----------------------------------------------------------------
  useRef
  -----------------------------------------------------------------
  */

  const pageNumber = useRef(0)  // current page, starts from 0
  const lastDimensions = useRef(browserUtils.getDimensionsObject())  // set baseline to current screen dimensions
  const payRespectBusy = useRef(false)
  const payRespectBlock = useRef(false)

  /*
  -----------------------------------------------------------------
  useState
  -----------------------------------------------------------------
  */

  const [breakpointDevice, setBreakpointDevice] = useState(browserUtils.getCurrentBreakpointDevice(bookConfig));

  const [desktopKey, setDesktopKey] = useState(1000000000)
  const [mobileKey, setMobileKey] = useState(2000000000)
  const [tabletKey, setTabletKey] = useState(3000000000)

  /*
  -----------------------------------------------------------------
  useEffect
  -----------------------------------------------------------------
  */

  useEffect(() => {

    // keypress events
    document.addEventListener('keydown', function (e) {

      // press F to pay respect
      if (e.key.toUpperCase() === 'F' && !payRespectBusy.current) {
        // exit if rose drop is blocked
        if (payRespectBlock.current) return
        // only fire if page with pay respects button is visible
        const items = document.querySelectorAll('.page-rose-drop')
        if (items !== null) {
          items.forEach((item) => {
            if (item.style.display === 'block') {
              payRespect()
            }
          })
        }
      }

    })

    // window orientation change handler: Safari
    try {
      window.addEventListener("orientationchange", function () {
        incrementKeys()
      }, false);
    } catch(e) {
      // ignore
    }

    // window resize handler
    const debouncedHandleResize = browserUtils.debounce(function handleResize() {

      // trigger flipbook regeneration if screen dimensions change by more than configured threshold (redrawPixels)
      const diffWidth = Math.abs(lastDimensions.current.width - window.innerWidth)
      const diffHeight = Math.abs(lastDimensions.current.height - window.innerHeight)
      if (diffWidth > bookConfig.react.redrawPixels || diffHeight > bookConfig.react.redrawPixels) {
        // reset baseline to current dimensions
        lastDimensions.current = browserUtils.getDimensionsObject()
        // hide books (to prevent flickering)
        const books = document.querySelectorAll('.book')
        if (typeof books === 'object' && books.length > 0) {
          books.forEach((book) => {
            console.log('here')
            book.style.display = 'none'
          })
        }
        // change keys to force component remounts
        incrementKeys()
      }

      // change book component on width breakpoints
      const newBreakpointDevice = browserUtils.getCurrentBreakpointDevice(bookConfig)
      if (breakpointDevice !== newBreakpointDevice) {
        // get adjusted page number
        pageNumber.current = flipbookUtils.getNewPage(pageNumber.current, breakpointDevice, newBreakpointDevice)
        // change device
        setBreakpointDevice(newBreakpointDevice)
      }

    }, 20);

    window.addEventListener('resize', debouncedHandleResize);
    return _ => {
      window.removeEventListener('resize', debouncedHandleResize);
    }

  })

  /*
  -----------------------------------------------------------------
  callbacks
  -----------------------------------------------------------------
  */

  const payRespect = () => {
    // only allow the rose to be dropped once
    if (payRespectBlock.current) {
      return false
    }
    // three second delay before the next rose can be dropped (if multiple drops are enabled)
    if (!payRespectBusy.current) {
      payRespectBusy.current = true
      payRespectBlock.current = true
      flipbookUtils.dropRose()
      flipbookUtils.disableDropRose()
      setTimeout(function(){
        payRespectBusy.current = false
      }, 3000)
    }
  }

  const pageNumberOnChange = (newPageNumber) => {
    pageNumber.current = parseInt(newPageNumber)
  }

  /*
  -----------------------------------------------------------------
  render: book view
  -----------------------------------------------------------------
  */

  return (
    <>

      {
        breakpointDevice === 'mobile' && 
        <BookMobile key={mobileKey} config={bookConfig.mobile} startOnPage={pageNumber.current} pageNumberOnChange={pageNumberOnChange} payRespectOnClick={payRespect} />
      }

      {
        breakpointDevice === 'tablet' &&
        <BookTablet key={tabletKey} config={bookConfig.tablet} startOnPage={4} pageNumberOnChange={pageNumberOnChange} payRespectOnClick={payRespect} />
      }

      {
        breakpointDevice === 'desktop' &&
        <BookDesktop  key={desktopKey} config={bookConfig.desktop} startOnPage={pageNumber.current} pageNumberOnChange={pageNumberOnChange} payRespectOnClick={payRespect} />
      }

    </>
  )

}

export default Book
