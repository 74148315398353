import { gsap } from 'gsap'

import config from './../config/index'

const frames_p1 = config.roseDrop.frames_p1
const frames_kill_p1 = config.roseDrop.frames_kill_p1
const frames_p2 = config.roseDrop.frames_p2

const flipbookUtils = {

/*
---------------------------------------------------------------
PROPERTIES
---------------------------------------------------------------
*/

dropRoseDisabled: false,

/*
---------------------------------------------------------------
FLIPBOOK PARAMS
---------------------------------------------------------------
*/

getFlipbookParams(deviceMode, widthAdj, config) {

    // get screen dimensions and aspect ratio
    const w = parseInt(window.innerWidth * widthAdj)
    const h = parseInt(window.innerHeight - config.heightAdj)

    // desktop
    if (deviceMode === 'desktop') {
      // fixed config
      return {
        width: 460,
        minWidth: 460,
        maxWidth: 650,
        height: 600,
        minHeight: 500,
        maxHeight: 800,
      }
    }

    // mobile + tablet
    if (h <= config.maxHeight) {
      if (w >= config.maxWidth) {
        // size by screen width
        // console.log('size by max width')
        const newH = deviceMode === 'tablet' ? h - 20 : h
        return {
          width: config.maxWidth,
          height: newH
        }
      } else {
        // size by screen height
        // console.log('size by screen height')
        return {
          width: w,
          height: h
        }
      }
    } else {
      // size by max height
      if (w >= config.maxWidth) {
        // console.log('size by max max width and max height')
        return {
          width: config.maxWidth,
          height: config.maxHeight - 20,
        }
      } else {
        // console.log('size by max height')
        return {
          width: w,
          height: config.maxHeight
        }
      }
    }

  },

  /*
  ---------------------------------------------------------------
  RESIZE IMAGES ON LOAD
  ---------------------------------------------------------------
  */

  resizeFlipbookImages() {
    setTimeout(function () {
      const interval = setInterval(() => {
        const allImagesLoaded = [...document.querySelectorAll('img')]
          .map(x => x.complete)
          .indexOf(false) === -1;
        if (allImagesLoaded) {
          clearInterval(interval);
          // flipbookUtils.resizeCoverDeco()
        }
      }, 25);
    }, 50)
  },

  /*
  ---------------------------------------------------------------
  COVER PAGE
  ---------------------------------------------------------------
  */

  // resizeCoverDeco() {

  //   // TODO: refactor so it only fires on mobile and tablet when the cover is visible

  //   const cover = document.querySelector('.page-cover')
  //   const coverDecoTop = document.querySelector('.cover-deco-top')
  //   const coverDecoMid = document.querySelector('.cover-deco-mid')
  //   const coverDecoBot = document.querySelector('.cover-deco-bot')

  //   if (cover !== null) {
  //     const coverDecoMidHeight = cover.clientHeight - (40 + coverDecoTop.height + coverDecoBot.height)
  //     if (coverDecoTop.style.display !== 'block') {
  //       coverDecoTop.style.display = 'block'
  //       coverDecoMid.style.display = 'block'
  //       coverDecoBot.style.display = 'block'
  //     }
  //     coverDecoMid.style.height = coverDecoMidHeight + 'px'
  //   }

  // },

  /*
  ---------------------------------------------------------------
  ROSE DROP
  ---------------------------------------------------------------
  */

  disableDropRose() {
    const elements = document.querySelectorAll('.respect-cta.enabled')
    if (typeof elements !== 'object' || elements === null) {
      // console.log('Respect button not found')
      return false
    }
    this.dropRoseDisabled = true
    elements.forEach((ele) => {
      if (ele.classList.contains('enabled')) {
        ele.classList.remove('enabled')
        ele.disabled = true
      }
    })
    return true
  },

  dropRose()  {
    if (this.dropRoseDisabled) {
      console.log('rose drop is disabled')
      return false
    }
    this.frameOne()
    return true
  },

  frameOne() {
    gsap.fromTo(['#rose-container'], 0.03, { autoAlpha: 0 }, { autoAlpha: 1 });
    gsap.fromTo(['#rose-container'], 0.5, { webkitFilter: 'blur(4px)' }, { webkitFilter: 'blur(0px)' });
    gsap.fromTo(['#rose-container'], 0.5, { filter: 'blur(4px)' }, { filter: 'blur(0px)' });
    gsap.to(frames_p1, { autoAlpha: 1, duration: 0.05, stagger: 0.05 });
    gsap.to(frames_kill_p1, { autoAlpha: 0, duration: 0.045, stagger: 0.05, delay: 0.06 });
    //gsap.delayedCall(2.5, flipbookUtils.frameTwo);
  },

  frameTwo() {
    gsap.to("#frame_20", { autoAlpha: 0, duration: 0.045 });
    gsap.to(frames_p2, { autoAlpha: 1, duration: 0.05, stagger: 0.05 });
    gsap.to(frames_p2, { autoAlpha: 0, duration: 0.045, stagger: 0.05, delay: 0.06 });
  },

  /*
  ---------------------------------------------------------------
  CONVERT PAGE NUMBER BETWEEN BREAKPOINTS
  ---------------------------------------------------------------
  */

  // this function is only called when a breakpoint change is detected
  getNewPage(prevPageNumber, prevBreakpointType, newBreakpointType) {

    if (prevPageNumber === 0) return 0

    switch (prevBreakpointType) {
      case 'desktop':
        switch (newBreakpointType) {
          case 'tablet':
            return prevPageNumber
          default:
            // mobile
            if (prevPageNumber > 1) return 5
            return 0
        }
      case 'tablet':
        switch (newBreakpointType) {
          case 'desktop':
            return prevPageNumber
          default:
            // mobile
            if (prevPageNumber > 1) return prevPageNumber + 1
            return prevPageNumber
        }
      default:
        // mobile
        switch (newBreakpointType) {
          case 'desktop':
            if (prevPageNumber > 2) return 2
            return 0
          default:
            // tablet
            if (prevPageNumber > 1) return prevPageNumber - 1
            return prevPageNumber
        }
    }
  
  }

}

export default flipbookUtils;
