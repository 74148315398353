import { useEffect, useRef, useState } from 'react'

import config from './../config/index'
import browserUtils from '../utils/browserUtils'

import Book from '../components/Book/Book'
import DeskVideo from '../components/DeskVideo'

import './Main.css'

const bookConfig = config.flipbook

const Main = () => {

  // helpers

  const isDesktop = () => {
    if (window.innerWidth >= bookConfig.tablet.breakpoint) return true
    return false
  }

  const hideAddThis = () => {
    const addThis = document.querySelector('.addthis-smartlayers')
    if (typeof addThis !== 'object' || addThis === null) return
    if (!addThis.classList.contains('hidden')) addThis.classList.add('hidden')
  }

  const showAddThis = () => {
    const addThis = document.querySelector('.addthis-smartlayers')
    if (typeof addThis !== 'object' || addThis === null) return
    if (addThis.classList.contains('hidden')) addThis.classList.remove('hidden')
  }

  // useRef

  const playedVideo = useRef(false)
  const isSkipped = useRef(false)

  // useState

  const [showVideo, setShowVideo] = useState(!playedVideo.current && isDesktop() ? true : false)
  const [breakpointDevice, setBreakpointDevice] = useState(browserUtils.getCurrentBreakpointDevice(bookConfig));

  // useEffect

  useEffect(() => {

    // AddThis: hide sharing while video is playing
    if (!isSkipped.current && showVideo) {
      if (showVideo) {
        hideAddThis()
      } else {
        showAddThis()
      }
    }

    // keypress: skip video
    document.addEventListener('keydown', function (e) {
      if (!isSkipped.current) {
        if (e.key === ' ' || e.key === 'Enter' || e.key === 'Escape' || e.key === 'Tab') {
          videoEndedHandler()
        }
      }
    })

    // resize: skip video if changing from desktop to tablet/mobile dimensions
    const debouncedHandleResize = browserUtils.debounce(function handleResize() {
      if (!isSkipped.current && breakpointDevice === 'desktop') {
        const currentBreakpointDevice = browserUtils.getCurrentBreakpointDevice(bookConfig)
        if (breakpointDevice !== currentBreakpointDevice) {
          videoEndedHandler()
          setBreakpointDevice(currentBreakpointDevice)
          showAddThis()
        }
      }
    }, 20);
    window.addEventListener('resize', debouncedHandleResize);
    return _ => window.removeEventListener('resize', debouncedHandleResize)

  }, [breakpointDevice, showVideo])

  // callbacks

  const videoEndedHandler = () => {
    if (isSkipped.current) return
    playedVideo.current = true
    isSkipped.current = true
    const videoEle = document.getElementById('DeskVideo')
    if (typeof videoEle === 'object' && videoEle !== null) {
      videoEle.classList.add('fade-out')
    }
    setTimeout(function () {
      const mainEle = document.getElementById('main')
      if (typeof mainEle === 'object' && mainEle !== null) {
        mainEle.classList.add('fade-in')
      }
      setShowVideo(false)
    }, 700)
  }

  const videoErrorHandler = () => {
    if (isSkipped.current) return
    playedVideo.current = true
    isSkipped.current = true
    setShowVideo(false)
  }

  // main

  return (
    <div id="main" className="main">

      {
        showVideo && <DeskVideo onEndedHandler={videoEndedHandler} onErrorHandler={videoErrorHandler} />
      }

      {
        !showVideo && 
        <main className='main-container'>
          <Book />
        </main>
      }

    </div>
  )

}

export default Main
