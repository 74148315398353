import {useEffect, useRef} from 'react'
import HTMLFlipBook from 'react-pageflip'

import flipbookUtils from '../../utils/flipbookUtils';

/*
-----------------------------------------------------------------
ASSETS
-----------------------------------------------------------------
*/

// cover page
import coverDecoTop from '../../assets/images/cover-deco-top.png'
import coverDecoMid from '../../assets/images/cover-deco-mid.png'
import coverDecoBot from '../../assets/images/cover-deco-bot.png'
import coverArt from '../../assets/images/cover-art.png'

// content page
import headerDecoLeft from '../../assets/images/page-header-deco-left.png'
import headerDecoRight from '../../assets/images/page-header-deco-right.png'
import footerDeco from '../../assets/images/page-footer-deco.png'

/*
-----------------------------------------------------------------
MAIN
-----------------------------------------------------------------
*/

const BookMobile = ({ config, startOnPage, pageNumberOnChange, payRespectOnClick }) => {

  /*
  -----------------------------------------------------------------
  useRef
  -----------------------------------------------------------------
  */

  const pageNumber = useRef(parseInt(startOnPage))
  const flipbookParams = useRef(flipbookUtils.getFlipbookParams('tablet', .96, config))

  /*
  -----------------------------------------------------------------
  useEffect
  -----------------------------------------------------------------
  */

  useEffect(() => {

    // if (pageNumber > 0) {
    //   HTMLFlipBook.setPage(pageNumber)
    // }

    flipbookUtils.resizeFlipbookImages()

  }, [])

  /*
  -----------------------------------------------------------------
  EVENT HANDLERS
  -----------------------------------------------------------------
  */

  // pay respect: call parent controller for rose drop
  const payRespect = () => {
    payRespectOnClick()
  }

  // update current page
  const onFlip = (flipbookData) => {
    pageNumber.current = flipbookData.data
    pageNumberOnChange(pageNumber.current)
  }

  /*
  -----------------------------------------------------------------
  MAIN
  -----------------------------------------------------------------
  */

  return (
    <>
      <div className='book book-mobile book-single'>
        <HTMLFlipBook
          width={flipbookParams.current.width}
          minWidth={flipbookParams.current.width}
          maxWidth={flipbookParams.current.width}
          height={flipbookParams.current.height}
          minHeight={flipbookParams.current.height}
          maxHeight={flipbookParams.current.height}
          size="stretch"
          autoSize={false}
          usePortrait={true}
          showCover={true}
          mobileScrollSupport={true}
          drawShadow={true}
          onFlip={onFlip}
          maxShadowOpacity={0.45}
        >

          <div number="1" className="page page-1 page-1-mobile page-bg page-cover" style={{ backgroundSize: `${flipbookParams.current.width}px, ${flipbookParams.current.height}px` }}>

            <div className="page-cover-container" aria-hidden="true">
              <img className="cover-deco cover-deco-top" src={coverDecoTop} alt="Decorative element" />
              <img className="cover-deco cover-deco-mid" src={coverDecoMid} alt="Decorative element" />
              <img className="cover-deco cover-deco-bot" src={coverDecoBot} alt="Decorative element" />
            </div>

            <img className="coverArt" src={coverArt} alt="In Memoriam - Jaya Ballard" />

          </div>

          <div number="2" className="page page-2 page-2-mobile page-bg illustration illustration-1" data-density="hard">
            <p className="sr-only">
              A young Jaya Ballard smirks confidently with her goggles off. Her Task Mage outfit is torn and she conjures flames from her finger, framing her figure with ropes of fire.
            </p>
          </div>

          <div number="3" className="page page-3 page-3-mobile page-bg page-right content" data-density="hard">

            <div className="page-header">
              <div className="left" aria-hidden="true">
                <img src={headerDecoLeft} alt="Decorative element" />
              </div>
              <div className="middle">
                <h1>IN MEMORIAM</h1>
              </div>
              <div className="right" aria-hidden="true">
                <img src={headerDecoRight} alt="Decorative element" />
              </div>
            </div>

            <div className="page-copy">
              <div className="page-col page-col-1 deco-left" aria-hidden="true">
                <div className="deco-left-top"></div>
                <div className="deco-left-mid"></div>
                <div className="deco-left-bottom"></div>
              </div>
              <div className="page-col page-col-2">

                <div className="copy-wrapper">

                  <div className="quotation">
                    <p className="large quote">“Who needs the sun when you've got <span className="nowrap">me around?”</span></p>
                    <p className="source smallCapsBold">&mdash; Jaya Ballard</p>
                  </div>

                  <div className="copy">
                    <p>
                      It is with a heavy heart that we announce the passing of the Planeswalker Jaya Ballard &mdash; hero, guardian, teacher, and fiery defender of Dominaria. She gave her life in defense of her home, a bright light during one of the Multiverse's darkest moments. She will be best remembered for her quick wit, her mentorship, and the inspiration she brought to so many while fighting for <span className="nowrap">the innocent.</span>
                    </p>
                  </div>

                </div>

              </div>
              <div className="page-col page-col-3 deco-right" aria-hidden="true">
                <div className="deco-right-top"></div>
                <div className="deco-right-mid"></div>
                <div className="deco-right-bottom"></div>
              </div>
            </div>

            <div className="page-footer" aria-hidden="true">
              <div className="middle">
                <img src={footerDeco} alt="Decorative element" />
              </div>
            </div>

          </div>

          <div number="4" className="page page-4 page-4-mobile page-bg page-right content" data-density="hard">

            <div className="page-header">
              <div className="left" aria-hidden="true">
                <img src={headerDecoLeft} alt="Decorative element" />
              </div>
              <div className="middle">
                <h1>IN MEMORIAM</h1>
              </div>
              <div className="right" aria-hidden="true">
                <img src={headerDecoRight} alt="Decorative element" />
              </div>
            </div>

            <div className="page-copy">
              <div className="page-col page-col-1 deco-left" aria-hidden="true">
                <div className="deco-left-top"></div>
                <div className="deco-left-mid"></div>
                <div className="deco-left-bottom"></div>
              </div>
              <div className="page-col page-col-2">

                <div className="copy-wrapper">
                  <div className="copy">

                    <p>
                      The entire Multiverse is dimmer without Jaya Ballard. She lost her life when her fellow Planeswalker, Ajani, was revealed as a compleated sleeper agent of Dominaria's long-time foes, <span className="nowrap">the Phyrexians.</span>
                    </p>

                    <p>
                      Jaya's flame burns on through her friends, her student Chandra Nalaar, the monks of Keral Keep, and the many, many individuals she fought for <span className="nowrap">and saved.</span>
                    </p>

                    <p className="large">
                      The fight is not yet over, but Jaya Ballard's flame <span className="nowrap">burns eternal.</span>
                    </p>

                  </div>
                </div>

              </div>
              <div className="page-col page-col-3 deco-right" aria-hidden="true">
                <div className="deco-right-top"></div>
                <div className="deco-right-mid"></div>
                <div className="deco-right-bottom"></div>
              </div>
            </div>

            <div className="page-footer" aria-hidden="true">
              <div className="middle">
                <img src={footerDeco} alt="Decorative element" />
              </div>
            </div>

          </div>

          <div number="5" className="page page-5 page-5-mobile page-bg page-right content" data-density="hard">

            <div className="page-header" aria-hidden="true">
              <div className="left">
                <img src={headerDecoLeft} alt="Decorative element" />
              </div>
              <div className="middle">
                <h1>GUESTS</h1>
              </div>
              <div className="right" aria-hidden="true">
                <img src={headerDecoRight} alt="Decorative element" />
              </div>
            </div>

            <div className="page-copy">
              <div className="page-col page-col-1 deco-left" aria-hidden="true">
                <div className="deco-left-top"></div>
                <div className="deco-left-mid"></div>
                <div className="deco-left-bottom"></div>
              </div>
              <div className="page-col page-col-2">

                <div className="copy-wrapper">
                  <div className="copy">

                    <p className="smallCapsBold guestlist">
                      Chandra Nalaar<br />
                      Teferi Akosa<br />
                      Jodah the Eternal<br />
                      Jhoira of the Ghitu<br />
                      Radha of Keld<br />
                      Danitha Capashen<br />
                      Raff Capashen<br />
                      Shanna Sisay<br />
                      Tura Kennerüd<br />
                      Queen Allenal<br />
                      King Darien XLVIII<br />
                      Sir Baird<br />
                      Tori D’Avenant<br />
                      Meria<br />
                    </p>

                    <p>
                      A rose is placed for our remembrance, but her flame will burn <span className="nowrap">for eternity.</span>
                    </p>

                    {/*
                    <p className="offsiteLink">
                      JAYA'S FINAL ACTS OF HEROISM
                      ARE <span className="nowrap">RECORDED <a href="https://magic.wizards.com/en/story?utm_source=jaya-ballard&utm_medium=micro-site&utm_campaign=august-superdrop-2022" target="_blank" rel="noreferrer">HERE</a></span>.
                    </p>
                    */}

                    <p className="offsiteLink">
                      KEEP JAYA'S MEMORY CLOSE AT HAND. 
                      OWN IN MEMORIAM: <span className="nowrap">JAYA BALLARD <a href="https://secretlair.wizards.com/?utm_source=jaya-ballard&utm_medium=micro-site&utm_campaign=august-superdrop-2022" target="_blank" rel="noreferrer" style={{ position: "relative", zIndex: "1000000" }}>HERE</a>.</span>
                    </p>

                  </div>
                </div>


              </div>
              <div className="page-col page-col-3 deco-right" aria-hidden="true">
                <div className="deco-right-top"></div>
                <div className="deco-right-mid"></div>
                <div className="deco-right-bottom"></div>
              </div>
            </div>

            <div className="page-footer" aria-hidden="true">
              <div className="middle">
                <img src={footerDeco} alt="Decorative element" />
              </div>
            </div>

          </div>

          <div number="6" className="page page-6 page-6-mobile page-bg illustration illustration-2 page-rose-drop" data-density="hard">

            <div id="rose-container" className="mobile" aria-hidden="true">
              <img className="rose-frame" id="frame_01" src="/assets/rose-sequence/01.png" alt=''/>
              <img className="rose-frame" id="frame_02" src="/assets/rose-sequence/02.png" alt=''/>
              <img className="rose-frame" id="frame_03" src="/assets/rose-sequence/03.png" alt=''/>
              <img className="rose-frame" id="frame_04" src="/assets/rose-sequence/04.png" alt=''/>
              <img className="rose-frame" id="frame_05" src="/assets/rose-sequence/05.png" alt=''/>
              <img className="rose-frame" id="frame_06" src="/assets/rose-sequence/06.png" alt=''/>
              <img className="rose-frame" id="frame_07" src="/assets/rose-sequence/07.png" alt=''/>
              <img className="rose-frame" id="frame_08" src="/assets/rose-sequence/08.png" alt=''/>
              <img className="rose-frame" id="frame_09" src="/assets/rose-sequence/09.png" alt=''/>
              <img className="rose-frame" id="frame_10" src="/assets/rose-sequence/10.png" alt=''/>
              <img className="rose-frame" id="frame_11" src="/assets/rose-sequence/11.png" alt=''/>
              <img className="rose-frame" id="frame_12" src="/assets/rose-sequence/12.png" alt=''/>
              <img className="rose-frame" id="frame_13" src="/assets/rose-sequence/13.png" alt=''/>
              <img className="rose-frame" id="frame_14" src="/assets/rose-sequence/14.png" alt=''/>
              <img className="rose-frame" id="frame_15" src="/assets/rose-sequence/15.png" alt=''/>
              <img className="rose-frame" id="frame_16" src="/assets/rose-sequence/16.png" alt=''/>
              <img className="rose-frame" id="frame_17" src="/assets/rose-sequence/17.png" alt=''/>
              <img className="rose-frame" id="frame_18" src="/assets/rose-sequence/18.png" alt=''/>
              <img className="rose-frame" id="frame_19" src="/assets/rose-sequence/19.png" alt=''/>
              <img className="rose-frame" id="frame_20" src="/assets/rose-sequence/20.png" alt=''/>
              <img className="rose-frame" id="frame_21" src="/assets/rose-sequence/21.png" alt=''/>
              <img className="rose-frame" id="frame_22" src="/assets/rose-sequence/22.png" alt=''/>
              <img className="rose-frame" id="frame_23" src="/assets/rose-sequence/23.png" alt=''/>
              <img className="rose-frame" id="frame_24" src="/assets/rose-sequence/24.png" alt=''/>
              <img className="rose-frame" id="frame_25" src="/assets/rose-sequence/25.png" alt=''/>
              <img className="rose-frame" id="frame_26" src="/assets/rose-sequence/26.png" alt=''/>
              <img className="rose-frame" id="frame_27" src="/assets/rose-sequence/27.png" alt=''/>
              <img className="rose-frame" id="frame_28" src="/assets/rose-sequence/28.png" alt=''/>
              <img className="rose-frame" id="frame_29" src="/assets/rose-sequence/29.png" alt=''/>
              <img className="rose-frame" id="frame_30" src="/assets/rose-sequence/30.png" alt=''/>
              <img className="rose-frame" id="frame_31" src="/assets/rose-sequence/31.png" alt=''/>
              <img className="rose-frame" id="frame_32" src="/assets/rose-sequence/32.png" alt=''/>
            </div>

            <p className='pay-respect-preamble mobile'>Click the button to pay respects.</p>
            <button className="respect-cta enabled mobile" onClick={payRespect} ></button>

            <p className="sr-only">
              Jaya Ballard stands old and wrinkled, but no less able, wielding circles of flame around her body in a wintery forest. She holds a staff and a ball of fire in preparation for what comes next.
            </p>

          </div>

        </HTMLFlipBook>
      </div>
    </>
  )

}

export default BookMobile
